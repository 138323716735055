import styled from '@emotion/styled'
import { GetStaticProps } from 'next'

import { H1 } from '@emico/ui'

import LogoWhite from '../components/LogoWhite'
import StoreSelectorCountryList from '../components/StoreSelectorCountryList'
import theme from '../theme'
import typography from '../theme/typography'

const Background = styled.div`
  background: url(/languagePickerBackground.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 554px;
  max-width: auto;
  margin: ${theme.spacing.md};
`

const Header = styled.header`
  background: ${theme.colors.primary};
  color: ${theme.colors.onPrimary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.xl};
`

const Logo = styled(LogoWhite)`
  width: 110px;
`

const HeaderSlogan = styled.div`
  text-transform: uppercase;
  font-style: italic;
  font-size: 10px;
  font-weight: ${typography.fontWeights.bold};
`

const Main = styled.main`
  background-color: ${theme.colors.background};
  padding: ${theme.spacing['2xl']};
`

const Title = styled(H1)`
  font-size: ${typography.fontSizes['2xl']};
`

const Question = styled.p`
  font-size: ${typography.fontSizes.lg};
  font-weight: ${typography.fontWeights.light};
  text-transform: uppercase;
  margin-bottom: ${theme.spacing.md};
`

export const getStaticProps: GetStaticProps = async () => ({
  revalidate: 3600,
  props: {},
})

export default function LanguagePicker() {
  return (
    <Background>
      <Wrapper>
        <Header>
          <Logo />
          <HeaderSlogan>The outdoors is yours</HeaderSlogan>
        </Header>
        <Main>
          <Title>Just one more thing</Title>
          <Question>From where do you want to fly, jump or ride?</Question>

          <StoreSelectorCountryList />
        </Main>
      </Wrapper>
    </Background>
  )
}
