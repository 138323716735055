import styled from '@emotion/styled'
import Image from 'next/image'
import React from 'react'

import LogoImage from '../images/berg_logo_white.png'
import theme from '../theme'

const StyledNextImage = styled(Image)`
  width: ${theme.sizes.logoWidth};
  height: auto;
`

const LogoWhite = ({ ...props }) => (
  <StyledNextImage
    width={277}
    height={141}
    src={LogoImage}
    alt="BergToysLogo"
    {...props}
  />
)

export default LogoWhite
